/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("CurrencyGuides/Add", item)
        .then(response => {
          commit("ADD_CurrencyGuide", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAllCurrencyGuideList({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("/CurrencyGuides/GetListOfCurrencyGuides")
        .then(response => {
          if (response.status == 200) {
            commit("SET_CurrencyGuide", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCurrencyGuideByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/CurrencyGuides/GetCurrencyGuide", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_CurrencyGuide", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CurrencyGuides/Update", item)
        .then(response => {
          debugger
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteItem(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CurrencyGuides/Delete" , item)
        .then(response => {
          debugger
          // commit("UPDATE_CurrencyGuide", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCCurrencyGuideById(context, CurrencyGuide) {
    if (CurrencyGuide.Id == undefined) {
      CurrencyGuide.Id = "";
    }

    return new Promise((resolve, reject) => {
      axios
        .post("/CurrencyGuides/Get", country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


};
