/*=========================================================================================
  File Name: moduleCurrencyGuide.js
  Description: CurrencyGuide Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleCurrencyGuideState.js";
import mutations from "./moduleCurrencyGuideMutations.js";
import actions from "./moduleCurrencyGuideActions.js";
import getters from "./moduleCurrencyGuideGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
